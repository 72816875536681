/*
    establish single direction margin/padding principle: https://csswizardry.com/2012/06/single-direction-margin-declarations/
    selectors come from http://meyerweb.com/eric/tools/css/reset/index.html
*/
/* stylelint-disable selector-list-comma-newline-after */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    /* stylelint-enable selector-list-comma-newline-after */
    margin-top: 0;
    padding-top: 0;
}

form {
    margin: 0;
}

svg use {
    fill: currentColor;
}

body {
    margin: 0;
    padding: 0 0 20px;
    font-family: var(--font-stack-sans-serif);
    font-size: var(--font-size-level-four);
    color: var(--tundora);
    background-color: var(--alabaster);
}

a {
    color: var(--lochmara);
    transition: opacity 0.15s ease-in-out;
}

a:hover {
    opacity: 0.6;
    text-decoration: none;
}

button {
    padding: 0;
    cursor: pointer;
    color: var(--lochmara);
    font-family: var(--font-stack-system);
    transition: opacity 0.15s ease-in-out;
}

button:hover {
    opacity: 0.6;
}

b {
    font-weight: var(--font-weight-normal);
}

ul {
    padding-left: 0;
    list-style-type: none;
}

img {
    max-width: 100%;
}

select {
    display: block;
    width: 100%;
    background-color: rgb(248, 248, 248);
    border-style: solid;
}

:global #wrapper {
    width: var(--main-width);
    margin: 0 auto;
    padding: 0;
    position: relative;
}
