:root {
	--button-height-tall: 40px;
	--button-height-short: 32px;
}
:root {
	--lochmara: #0079d0;
	--guardsmanRed: #ce0000;
	--chablis: #fff4f4;
	--limeade: #478500;
	--selectiveYellow: #ffb800;
	--silver: #c0c0c0;
	--white: #fff;
	--mineShaft: #2f2f2f;
	--tundora: #414141;
	--doveGray: #6d6d6d;
	--silverChalice: #adadad;
	--mercury: #e1e1e1;
	--alabaster: #f9f9f9;
}
:root {
	--story-text-gutter: 36px;
	--main-width: 1025px;
	--card-padding: 20px;
	--alert-modal-width: 550px;
}
:root {
	--font-size-level-one: 24px;
	--font-size-level-two: 19px;
	--font-size-level-three: 16px;
	--font-size-level-four: 14px;
	--font-size-level-five: 11px;
	--font-size-level-six: 17px;
	--font-weight-bold: 700;
	--font-weight-medium: 500;
	--font-weight-regular: 400;
	--font-stack-serif: Georgia, Times, Times New Roman, serif;
	--font-stack-sans-serif: Helvetica, arial, sans-serif;
	--font-stack-system: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	--standard-letter-spacing: 0.2px;
}