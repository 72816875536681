:root {
	--button-height-tall: 40px;
	--button-height-short: 32px;
}
:root {
	--lochmara: #0079d0;
	--guardsmanRed: #ce0000;
	--chablis: #fff4f4;
	--limeade: #478500;
	--selectiveYellow: #ffb800;
	--silver: #c0c0c0;
	--white: #fff;
	--mineShaft: #2f2f2f;
	--tundora: #414141;
	--doveGray: #6d6d6d;
	--silverChalice: #adadad;
	--mercury: #e1e1e1;
	--alabaster: #f9f9f9;
}
:root {
	--story-text-gutter: 36px;
	--main-width: 1025px;
	--card-padding: 20px;
	--alert-modal-width: 550px;
}
:root {
	--font-size-level-one: 24px;
	--font-size-level-two: 19px;
	--font-size-level-three: 16px;
	--font-size-level-four: 14px;
	--font-size-level-five: 11px;
	--font-size-level-six: 17px;
	--font-weight-bold: 700;
	--font-weight-medium: 500;
	--font-weight-regular: 400;
	--font-stack-serif: Georgia, Times, Times New Roman, serif;
	--font-stack-sans-serif: Helvetica, arial, sans-serif;
	--font-stack-system: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	--standard-letter-spacing: 0.2px;
}
/*
    establish single direction margin/padding principle: https://csswizardry.com/2012/06/single-direction-margin-declarations/
    selectors come from http://meyerweb.com/eric/tools/css/reset/index.html
*/
/* stylelint-disable selector-list-comma-newline-after */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    /* stylelint-enable selector-list-comma-newline-after */
    margin-top: 0;
    padding-top: 0;
}

form {
    margin: 0;
}

svg use {
    fill: currentColor;
}

body {
    margin: 0;
    padding: 0 0 20px;
    font-family: var(--font-stack-sans-serif);
    font-size: var(--font-size-level-four);
    color: var(--tundora);
    background-color: var(--alabaster);
}

a {
    color: var(--lochmara);
    -webkit-transition: opacity 0.15s ease-in-out;
    transition: opacity 0.15s ease-in-out;
}

a:hover {
    opacity: 0.6;
    text-decoration: none;
}

button {
    padding: 0;
    cursor: pointer;
    color: var(--lochmara);
    font-family: var(--font-stack-system);
    -webkit-transition: opacity 0.15s ease-in-out;
    transition: opacity 0.15s ease-in-out;
}

button:hover {
    opacity: 0.6;
}

b {
    font-weight: var(--font-weight-normal);
}

ul {
    padding-left: 0;
    list-style-type: none;
}

img {
    max-width: 100%;
}

select {
    display: block;
    width: 100%;
    background-color: rgb(248, 248, 248);
    border-style: solid;
}

#wrapper {
    width: var(--main-width);
    margin: 0 auto;
    padding: 0;
    position: relative;
}

.buttonBase {
    position: relative;
    height: var(--button-height-short);
    padding: 0 8px; /* safety padding */
    border-radius: 5px;
    font-size: var(--font-size-level-four);
    font-weight: var(--font-weight-medium);
    text-decoration: none;
    letter-spacing: var(--standard-letter-spacing);
    width: 100%;
}

.buttonNeutral {
    border: 1px solid var(--lochmara);
    background-color: var(--white);
    color: var(--lochmara);
}

.buttonPrimary {
    background: var(--lochmara);
    color: var(--white);
}

.buttonAttention {
    background-color: var(--guardsmanRed);
    color: var(--white);
}

.buttonTall {
    height: var(--button-height-tall);
}

.buttonInfoBadge {
    background: var(--mineShaft);
    color: var(--white);
}

.buttonInfoBadge > svg {
    margin-right: 5px;
    height: 15px;
    vertical-align: sub;
    line-height: 1.4;
}

/* unfortuante selector to override a Seamus concern */
.buttonInfoBadge > svg + span {
    font-family: var(--font-stack-system);
    font-size: var(--font-size-level-four);
    color: var(--white);
    line-height: 1.4;
}

/* button groups */
.buttonContainer {
    clear: left;
    border-top: 1px solid #ddd;
}

.buttonContainer button {
    line-height: .9;
}

.buttonContainer ul {
    display: inline-block;
    overflow: hidden;
    margin-bottom: 0;
    padding: var(--card-padding);
}

.buttonContainer li {
    float: left;
    width: inherit;
    margin-bottom: 0;
    list-style: none;
}

.buttonContainer li {
    padding-right: 5px;
    padding-left: 5px;
}

.buttonContainer li:first-child {
    padding-left: 0;
}

.buttonContainer li:last-child {
    padding-right: 0;
    margin-bottom: 0;
}
/* card styles - e.g. Seamus resources, Station Syndication story item */
.cardBase {
    margin: 8px 0;
    padding: 15px;
    border: 1px solid #ddd;
    background-color: var(--alabaster);
    letter-spacing: var(--standard-letter-spacing);
    cursor: -webkit-grab;
    cursor: grab;
}

.cardWrap {
    position: relative;
    overflow: hidden;
    padding: var(--card-padding);
}

.cardMetadata {
    overflow: hidden;
    margin-bottom: 15px;
}

.cardMetadata:last-child {
    margin-bottom: 0;
}

.cardMetadataHeading {
    margin-bottom: 0;
    color: var(--tundora);
    font-size: var(--font-size-level-four);
    font-weight: var(--font-weight-medium);
}

.cardMedia {
    float: right;
    width: calc(50% + 20px);
    padding-left: 20px;
}

.cardMediaSubdued {
    float: right;
    width: calc(25% + 20px);
    padding-left: 20px;
}

/* label/heading and character count */
.headingAndCountGroup {
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.headingAndCountGroup > h3 {
    margin: 0;
    font-size: var(--font-size-level-four);
    font-weight: var(--font-weight-medium);
}

.headingAndCountGroup > h3 > label,
.headingAndCountGroup > h3[role="button"] {
    cursor: pointer;
}

.headingAndCountGroup > div {
    margin-left: auto;
    font-size: var(--font-size-level-four);
    line-height: 1.06em;
}

.headingAndCountGroup > div > b {
    margin-right: 2px;
    font-weight: var(--font-weight-medium);
}

.overlay {
    position: fixed;
    overflow-y: auto; /* if modal gets too tall, user can scroll the overlay vertically */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 4; /* above story-lock overlay (2) and resources (3) */
    width: 100%;
    height: 100%;
    background: rgba(32, 32, 32, 0.5);
    font-family: var(--font-stack-system);
    line-height: 1.2;
}

.modal {
    overflow: visible;
    position: relative;
    top: 45px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 750px;
    margin: auto 0 25px;
    padding: 25px;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    background-color: var(--white);
    color: #000;
    letter-spacing: var(--standard-letter-spacing);
}

.modalClose {
    font-weight: var(--font-weight-medium);
}

.modalHeader {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.modalTitle {
    float: left;
    margin: 0;
    color: var(--mineShaft);
    font-size: var(--font-size-level-one);
    font-weight: var(--font-weight-regular);
}

.modalSectionHeader {
    margin: 20px 0 10px;
    color: var(--mineShaft);
    font-weight: var(--font-weight-medium);
}

.alertTitle {
    float: left;
    margin: 0;
    color: var(--mineShaft);
    font-size: var(--font-size-level-two);
    font-weight: var(--font-weight-medium);
}

.modalButtonGroup {
    clear: left;
    overflow: hidden;
    margin-bottom: 0;
}

.modalButtonGroup li {
    float: left;
    padding-right: 10px;
}

.modalButtonGroup button,
.modalButtonGroup a {
    height: var(--button-height-short);
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.modalButtonGroup a {
    display: block;
    line-height: var(--button-height-short);
}

/* typeahead item in modal */
.modal [aria-expanded="true"] + div > div {
    display: flex;
    align-items: center;
    height: 52px;
    padding-left: 16px;
    border-top: 1px solid var(--silverChalice);
    border-right: 1px solid var(--silverChalice);
    border-left: 1px solid var(--silverChalice);
    font-size: var(--font-size-level-three);
    cursor: pointer;
}

.modal [aria-expanded="true"] + div > div:last-child {
    border-bottom: 1px solid var(--silverChalice);
}
/* high level modules, such as "Associations" in Seamus */
.moduleBase {
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #e9e9e9;
    background-color: var(--white);
    letter-spacing: var(--standard-letter-spacing);
}

.moduleBase::after {
    content: "";
    display: table;
    clear: both;
}

.moduleHeader {
    border-bottom: 1px solid #d6d6d6;
    margin-bottom: 10px;
}

.moduleHeading {
    color: var(--tundora);
    font-size: var(--font-size-level-two);
    font-weight: var(--font-weight-regular);
    line-height: 1.06;
}

/* utilities */
.visuallyHidden {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.clearfix::after {
    content: "";
    display: table;
    clear: both;
}

html, body {
	padding: 0;
	margin: 0;
	border: 0;
	width: 100%;
	height: 100%;
	background-color: #fff;
}

:root {
	--main-background: #ebebeb;
	--main-padding: 0 2rem;
	--selection-background: var(--alabaster);
}

ul {
	list-style: none;
	margin: 0;
	padding: 3px 1px;
	overflow: auto;
}

button:hover {
	box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
	-webkit-transition: all 100ms ease-in-out;
	transition: all 100ms ease-in-out;
}

#root {
	overflow: hidden;
}

#root>div {
	height: 100vh;
	display: flex;
	flex-direction: column;
}

.appHeader a {
	text-decoration: none;
	color: #444;
}

.hidden {
	display: none !important;
}

label.invalid input {
	outline-color: var(--guardsmanRed);
	border: 1px solid var(--guardsmanRed) !important;
}

.loading {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: #ebebeb;
	background-color: var(--main-background);
}

.loading.failed>svg {
	padding: 1rem;
	opacity: 0.2;
}

.dropzoneWrapper {
	height: 100%;
	background-color: #ebebeb;
	background-color: var(--main-background);
	border-radius: 2px !important;
	padding: 0 2rem;
	padding: var(--main-padding);
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	align-items: center;
	overflow-y: auto;
}

.dropzoneWrapper>h2 {
	width: 100%;
	margin: 2rem auto 1rem auto;
	display: flex;
}

.dropzoneWrapper>div {
	display: flex;
	justify-content: center;
	padding: 1rem;
	width: 100%;
	min-height: 200px;
}

.dropzoneWrapper .dropZone {
	border: 2px dashed var(--lochmara) !important;
	background: #fff;
	border-radius: 5px;
	cursor: pointer;
	width: 100% !important;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.dropzoneWrapper .dropZone:hover {
	background: var(--alabaster)
}

.dropzoneWrapper .progress {
	border-radius: 3px;
	background-color: #fff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
	cursor: pointer;
	width: 100% !important;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.dropzoneWrapper .progress .percent {
	font-size: 1.1rem;
	width: 80%;
	display: flex;
}

.dropzoneWrapper .progress .percent>div:first-child {
	flex-grow: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	white-space: nowrap;
}

.dropzoneWrapper .progress .progressBar {
	height: 10px;
	background: #cae1ff;
	width: 80%;
	border-radius: 5px;
	overflow: hidden;
	margin: 1rem auto;
}

.dropzoneWrapper .progress .progressBar>div {
	height: 100%;
	background: var(--lochmara);
}

.dropzoneWrapper .csvInfo {
	min-height: auto;
	display: grid;
	grid-column-gap: 0px;
	grid-template-columns: 50% 50%;
}

.dropzoneWrapper .csvInfo div:first-child {
	background: var(--mercury);
	padding: 1rem;
}

.dropzoneWrapper .csvInfo ul {
	list-style: inside;
}

.dropzoneWrapper .csvInfo ul li {
	padding: 0.5rem;
}

.dropzoneWrapper .csvInfo .csvExample {
	font-weight: bold;
	background: var(--mercury);
	padding: 1rem;
}

.dropzoneWrapper .csvInfo pre {
	background: #fff;
	padding: 0.5rem;
	overflow: auto;
	font-weight: normal;
	margin-top: 1rem;
}

.howItWorks {
	background: var(--mercury);
	padding: 0 !important;
	margin: 0 0 1rem 0 !important;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	min-height: 170px !important;
}

.howItWorks h3 {
	width: calc(100% - 2rem);
	padding: 1rem 0 0 1rem;
}

.howItWorks ul {
	display: flex;
	overflow: visible;
	margin-top: 2rem;
	border-style: dotted;
	border-color: var(--lochmara);
	border-image-source: url(/static/media/dots.b8543ac4.svg) !important;
	border-image-slice: 30% 29% !important;
	border-image-repeat: round !important;
	border-width: 8px 0 0 0;
	flex-grow: 1;
}

.howItWorks ul>ol {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 220px;
	max-width: 220px;
	text-align: center;
	margin-top: -23px;
	margin-left: 4px;
}

.howItWorks ul>ol:first-child {
	margin-left: -136px;
}

.howItWorks ul>ol:last-child {
	margin-right: -96px;
}

.howItWorks ul>ol>span {
	width: 30px;
	height: 30px;
	background: var(--lochmara);
	color: #fff;
	border-radius: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 5px;
	font-weight: bold;
}

.error, .info {
	background-color: var(--guardsmanRed);
	color: #fff;
	padding: 0 2rem;
	padding: var(--main-padding);
	padding-top: 1rem;
	padding-bottom: 1rem;
	display: flex;
	align-items: center;
}

.info {
	background-color: var(--lochmara);
	color: #fff;
}

.info .message {
	max-width: 800px;
}

.error svg, .info svg {
	fill: #fff !important;
	width: 16px;
	height: 16px;
}

.error .title, .info .title {
	font-weight: bold;
	padding: 0 0.5rem
}

.error a, .info a {
	color: #fff;
	text-decoration: underline;
}

.error button.close, .info button.close {
	color: #fff;
	flex-grow: 1;
	text-align: right;
	box-shadow: none;
}

.warning {
	background-color: var(--selectiveYellow);
	font-weight: normal;
	padding: 1rem;
	border-radius: 3px;
}

aside.warning {
	margin-top: 1rem;
}

aside.warning span {
	text-transform: uppercase;
	font-weight: bold;
}

.msg {
	padding: 1rem;
	background-color: var(--guardsmanRed);
	color: #fff;
	margin: 2rem;
	border-radius: 3px;
	border: 1px solid rgba(27, 31, 35, 0.15);
	min-height: 3rem !important;
	max-height: 3rem !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.msg a {
	/* color: var(--lochmara); */
	color: #fff;
	text-decoration: underline;
}

.dropzoneWrapper .msg {
	padding: 1rem 0;
	margin: 0.2rem;
	border-radius: 3px;
	border: 1px solid rgba(27, 31, 35, 0.15);
	min-height: 1rem !important;
	max-height: 1rem !important;
}

.dropzoneWrapper button {
	font-size: inherit;
	background: transparent;
	border: 0;
	padding: 0;
	color: var(--lochmara);
	cursor: pointer;
	border-bottom: 1px solid transparent;
}

.dropzoneWrapper button:hover {
	border-bottom: 1px solid var(--lochmara);
	box-shadow: none;
	opacity: 1;
}

.dropzoneWrapper button:disabled {
	color: var(--mercury);
}

.appHeader {
	display: flex;
	align-items: center;
	background-color: var(--mercury);
	padding: 0.5rem 2rem 0.5rem 1.8rem;
	margin: 0;
}

.appHeader>a {
	display: flex;
	height: 1rem;
	padding: 0.5rem 0.5rem 0.5rem 0.2rem;
	white-space: nowrap;
	align-items: center;
	font-size: 1rem;
}

.appHeader>a:hover {
	opacity: 1;
	background: #ebebeb;
	background: var(--main-background);
}

.appHeader .version {
	flex-grow: 1;
	text-align: right;
	font-size: 0.875rem;
	color: #777;
}

.appHeader .feedback {
	padding-left: 1rem;
}

.NPRLogo {
	height: 20px;
	padding-right: 0.5rem;
}

.appMain {
	height: 100%;
	overflow: auto;
	background: #ebebeb;
	background: var(--main-background);
	border-radius: 2px !important;
	padding: 0 2rem;
	padding: var(--main-padding);
	display: grid;
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	grid-template-columns: 400px auto;
	grid-template-rows: 40px 200px calc(50% - 135px) calc(50% - 135px);
	grid-template-areas: "header header" "waveform waveform" "trackingUrls eventsWrapper" "customMetadata eventsWrapper";
}

.appMain svg {
	pointer-events: none;
}

@media only screen and (max-width: 1200px) {
	.appMain {
		grid-template-columns: auto 400px;
		grid-template-rows: 40px 200px 200px auto;
		grid-template-areas: "header header" "trackingUrls customMetadata" "waveform waveform" "eventsWrapper eventsWrapper";
	}
	.appMain .customMetadata {
		grid-area: customMetadata;
		margin-bottom: 0 !important;
	}
}

/* Main layout parts */

.appMain .mainHeader {
	grid-area: header
}

.appMain .waveform {
	grid-area: waveform;
	flex-direction: column;
}

.appMain .eventsWrapper {
	grid-area: eventsWrapper;
}

.appMain .trackingUrls {
	grid-area: trackingUrls;
}

.appMain .customMetadata {
	grid-area: customMetadata;
	margin-bottom: 10px;
}

.appMain .customMetadata ul {
	margin: 1rem 0 0 1rem;
	padding-right: 0.5rem;
}

.appMain .customMetadata ul>li {
	padding: 0 0 0.7rem 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.appMain .customMetadata ul>li>span {
	padding: 0 0.3rem;
}

.appMain .card {
	border-radius: 3px;
	background-color: #fff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.09);
	display: grid;
	grid-column-gap: 3px;
	grid-row-gap: 3px;
	grid-template-rows: 2.8rem auto;
	grid-template-areas: "sectionHeader" "sectionContent";
	overflow: hidden;
}

.appMain .card>header, .template h3 {
	padding: 0.5rem 0.5rem 0.5rem 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-transform: uppercase;
	font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
	letter-spacing: 1.08px;
	font-size: 0.75rem;
	color: rgb(118, 118, 118);
	grid-area: sectionHeader;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
}

.appMain .card>header button {
	letter-spacing: 0;
}

.appMain .card>ul {
	grid-area: sectionContent;
}

.appMain .card>header button {
	padding: 0.2rem 0.4rem;
}

.appMain .card.invalid>header {
	color: var(--guardsmanRed);
}

/* Main Header */

.appMain .mainHeader {
	display: grid;
	margin: 0;
	height: 53px;
	max-width: 100%;
	grid-template-columns: auto 460px;
	grid-template-areas: "fileName headerButtons";
	grid-template-rows: auto;
}

.appMain .mainHeader a {
	font-size: 1rem;
}

.appMain .mainHeader .fileName {
	flex-grow: 1;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	grid-area: fileName;
	align-self: center;
}

.appMain .headerButtons {
	display: flex;
	justify-content: flex-end;
	overflow: visible;
	grid-area: headerButtons;
	align-self: center;
}

.appMain .mainHeader button {
	min-width: 130px;
}

.appMain .mainHeader .downloadAudio {
	margin-right: 0.5rem !important;
	min-width: 150px;
}

.appMain .eventsWrapper {
	-webkit-transition: all .3s;
	transition: all .3s;
	display: grid;
	grid-column-gap: 0;
	grid-row-gap: 10px;
	grid-template-columns: auto 0;
	grid-template-rows: auto;
	grid-template-areas: "events eventEditor";
	overflow: hidden;
	padding-bottom: 10px;
	min-height: 200px;
}

.appMain .eventsWrapper.showEditor {
	grid-column-gap: 10px;
	grid-template-columns: auto 400px;
}

.appMain .eventsWrapper .events {
	grid-area: events;
}

.appMain .eventsWrapper .events.pagination {
	grid-template-rows: 2.8rem auto 2.4rem;
	grid-template-areas: "sectionHeader" "sectionContent" "sectionFooter";
}

.appMain .eventsWrapper .events header span {
	display: flex;
}

.appMain .eventsWrapper .events footer {
	display: none;
	color: rgb(118, 118, 118);
	letter-spacing: 1.08px;
	font-size: 0.75rem;
}

.appMain .eventsWrapper .events.pagination footer {
	display: flex;
	align-items: center;
	justify-content: center;
	grid-area: sectionFooter;
	box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.07);
}

.appMain .eventsWrapper .events footer button {
	width: 24px;
	height: 24px;
	min-width: 24px;
	min-height: 24px;
	padding: 0;
}

.appMain .eventsWrapper .events footer .range {
	padding-left: 0.6rem;
}

.appMain .eventsWrapper .eventEditor {
	grid-area: eventEditor;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-left: 4px solid var(--lochmara);
}

.appMain .eventsWrapper .eventEditor.xmlViewer {
	border-left: 4px solid var(--selectiveYellow);
}

.appMain .eventsWrapper:not(.showEditor) .eventEditor {
	display: none;
}

.appMain .eventsWrapper .eventEditor input[type='text'] {
	max-width: 140px;
}

.appMain .eventsWrapper .eventEditor ul {
	margin: 0 0 0 0.5rem;
}

.appMain .eventsWrapper .eventEditor ul>li {
	padding: 0 0 0.7rem 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.appMain .eventsWrapper .eventEditor ul>li>span {
	padding: 0 0.3rem;
}

.appMain .eventEditor h4 {
	padding: 0.5rem 0.6rem;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	font-weight: normal;
	margin: 0;
	font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
	letter-spacing: 1.08px;
	font-size: 0.75rem;
	color: rgb(118, 118, 118);
}

.appMain .eventEditor .eventTimeLabel {
	display: flex;
	margin-bottom: 0.5rem;
}

.appMain .eventEditor .eventEditorContent {
	overflow: auto;
	margin: 0.5rem;
}

button.close {
	border: 0;
	font-size: 0.9rem;
	color: rgb(118, 118, 118);
	cursor: pointer;
	background: transparent;
}

button.primary, button.secondary {
	background: var(--lochmara);
	color: #fff;
	border: 1px solid transparent;
	padding: 0.3rem 0.6rem;
	font-size: 0.8rem;
	margin: 0 0 0 0.5rem;
	cursor: pointer;
	border-radius: 3px;
	white-space: nowrap;
	height: var(--button-height-short);
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 100px;
	text-transform: none;
	text-transform: initial;
}

button.secondary {
	background: #fff;
	color: var(--lochmara);
	border: 1px solid var(--lochmara);
}

button.primary:disabled {
	background: var(--doveGray);
	opacity: 0.5;
}

button.secondary:disabled {
	opacity: 0.5;
}

button .loading {
	background: transparent;
}

button .loading .spinner-wave {
	margin: 0;
	height: 1rem;
	width: 32px;
}

button.primary .loading .spinner-wave>div {
	background-color: #fff;
}

/* Waveform */

.appMain .waveform {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 200px;
}

.appMain .trackingUrls ul {
	margin: 1rem 0.5rem 1rem 1rem;
}

.appMain .trackingUrls li {
	padding: 0 0 0.7rem 0;
	display: flex;
}

label.trackingUrlItem {
	display: flex;
	flex-grow: 1;
}

label.trackingUrlItem input {
	width: 100%;
	max-width: inherit;
	flex-grow: 1;
}

label.metadata, label.eventTime, label.eventLabel, div.eventLabel {
	display: flex;
	flex-direction: column;
	padding: 0.5rem 0.5rem 0rem 0.5rem;
	font-size: 0.875rem;
}

.readFromUrl {
	min-height: 120px !important;
	align-items: center;
	padding: 0 !important;
	margin: 1rem;
	background-color: #fff;
}

.readFromUrl label {
	min-height: 2.2rem;
}

.readFromUrl .readFromUrlControls {
	display: flex;
	width: 90%;
}

.readFromUrl .progress {
	height: 120px;
}

.readFromUrl .progress .loading {
	background-color: inherit;
	text-align: center;
}

.readFromUrl .progress .loading .spinner-wave {
	display: flex;
	justify-content: center;
}

.eventsContainer li {
	-webkit-transition: background-color 0.3s, border-left 0.3s;
	transition: background-color 0.3s, border-left 0.3s;
	height: 2.3rem;
	display: flex;
	align-items: center;
	padding: 0 0.5rem;
	border-bottom: 1px solid #f0f0f0;
	border-left: 4px solid transparent;
	font-size: 0.875rem;
}

.eventsContainer li.selected {
	background-color: var(--alabaster);
	background-color: var(--selection-background);
	border-left: 4px solid var(--lochmara);
	border-bottom: 0;
	padding-bottom: 1px;
}

.eventsContainer li.selected.invalid {
	border-left: 4px solid var(--guardsmanRed);
}

.eventsContainer li:not(.selected):not(.readonly):hover {
	background-color: #fafafa;
}

.eventsContainer li.overmax {
	border-left: 4px solid var(--selectiveYellow);
}

.eventsContainer li.interval {
	background-color: rgba(0, 121, 208, 0.05);
}

.eventsContainer li.readonly {
	font-style: italic;
	color: var(--doveGray);
}

.tags h2 button:first-child {
	margin: 0;
}

.addButton, .editButton, .deleteButton {
	display: none;
}

button.play, button.pause, button.add, button.edit, button.delete {
	width: 28px;
	min-width: 28px;
	height: 28px;
	min-height: 28px;
	border-radius: 14px;
	cursor: pointer;
	margin: 0 5px;
	background: #fff;
	color: var(--lochmara);
	border: 1px solid var(--mercury);
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0 !important;
	overflow: hidden;
}

button.play, button.pause {
	margin-left: 0.5rem;
}

.buttons {
	display: flex;
	justify-content: center;
}

.buttons svg, .pause svg, .add svg, .edit svg, .delete svg {
	fill: var(--lochmara) !important;
	width: 10px;
	height: 10px;
}

.pause:disabled svg, .add:disabled svg, .edit:disabled svg, .delete:disabled svg {
	fill: var(--silverChalice) !important;
}

.play svg {
	fill: var(--lochmara) !important;
	width: 13px;
	height: 13px;
	padding-left: 3px;
}

.viewAdMetadata {
	margin-right: 0.4rem !important;
	min-width: 65px !important;
	color: #444 !important;
	background: var(--selectiveYellow) !important;
}

.viewAdMetadata svg {
	fill: #444 !important;
	width: 13px;
	height: 13px;
	padding-right: 3px;
}

.labelTextInput {
	flex-grow: 1;
}

.eventsContainer>li>span {
	white-space: nowrap;
}

.eventsContainer label {
	padding: 2px 5px 2px 2px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	align-items: center;
}

input[type="text"] {
	border: 1px solid var(--silver);
	margin: 2px 0;
	padding: 4px;
	font-size: 0.875rem;
	max-width: 150px;
}

input[type="text"]:hover, input[type="text"]:focus, input[type="text"]:active {
	border: 1px solid var(--lochmara);
}

.rotated90 {
	-webkit-transform: rotate(90deg);
	        transform: rotate(90deg);
}

.labelTextInput input {
	width: 100%;
}

.positionTextInput {
	min-width: 6rem;
	display: flex;
}

.positionTextInput div {
	display: flex;
}

.positionTextInput svg {
	width: 1rem;
	height: 1rem;
	margin-right: 5px;
	fill: var(--selectiveYellow);
}

.invalidEvent .positionTextInput svg {
	fill: var(--guardsmanRed);
}

.positionTextInput input {
	width: 7rem;
}

.spinner-wave {
	width: 50px;
	height: 40px;
	margin: 1rem;
}

.spinner-wave>div {
	background-color: var(--lochmara);
	height: 100%;
	width: 3px;
	margin: 1px;
	display: inline-block;
	-webkit-animation: wave 1.2s infinite ease-in-out;
	animation: wave 1.2s infinite ease-in-out;
}

.spinner-wave div:nth-child(2) {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}

.spinner-wave div:nth-child(3) {
	-webkit-animation-delay: -1.0s;
	animation-delay: -1.0s;
}

.spinner-wave div:nth-child(4) {
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}

.spinner-wave div:nth-child(5) {
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}

@-webkit-keyframes wave {
	0%, 40%, 100% {
		-webkit-transform: scaleY(0.4)
	}
	20% {
		-webkit-transform: scaleY(1.0)
	}
}

@keyframes wave {
	0%, 40%, 100% {
		-webkit-transform: scaleY(0.4);
		        transform: scaleY(0.4);
	}
	20% {
		-webkit-transform: scaleY(1.0);
		        transform: scaleY(1.0);
	}
}

.tooltip.type-error {
	color: #fff !important;
	background-color: var(--guardsmanRed) !important;
}

.tooltip.type-error.place-top:after {
	border-top-color: var(--guardsmanRed) !important;
	border-top-style: solid !important;
	border-top-width: 6px !important;
}

.tooltip.type-error.place-right:after {
	border-right-color: var(--guardsmanRed) !important;
	border-right-style: solid !important;
	border-right-width: 6px !important;
}

.tooltip.type-error.place-left:after {
	border-left-color: var(--guardsmanRed) !important;
	border-left-style: solid !important;
	border-left-width: 6px !important;
}

.tooltip.type-warning {
	color: #444 !important;
	opacity: 1 !important;
	background-color: var(--selectiveYellow) !important;
}

.tooltip.type-warning.place-top:after {
	border-top-color: var(--selectiveYellow) !important;
	border-top-style: solid !important;
	border-top-width: 6px !important;
}

.tooltip.type-warning.place-right:after {
	border-right-color: var(--selectiveYellow) !important;
	border-right-style: solid !important;
	border-right-width: 6px !important;
}

.tooltip.type-warning.place-left:after {
	border-left-color: var(--selectiveYellow) !important;
	border-left-style: solid !important;
	border-left-width: 6px !important;
}

.waveform {
	position: relative;
}

.waveform .loading {
	position: absolute;
	background: #fff;
}

.waveform.audio-error {
	background: #fff url(/static/media/waveform-error.4ed82d37.png) no-repeat center;
	background-size: auto 126px;
}

.waveform.audio-error #wave-form {
	display: none;
}

.waveform.audio-error #wave-controls, .waveform.audio-error #wave-timeline, .waveform.loading #wave-controls {
	display: none;
}

.waveform:not(.loading) .loading {
	display: none;
}

#wave-controls {
	display: flex;
}

#wave-controls button.secondary {
	border: 1px solid var(--mercury);
	padding: 0rem 0.6rem;
	margin-right: 0.5rem;
	margin-left: 0;
}

#wave-controls span {
	flex-grow: 1;
	text-align: right;
	padding-right: 1rem;
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: flex-end;
	font-weight: bold;
}

#wave-controls span.warning {
	flex-grow: 0;
	padding: 3px 10px;
	white-space: nowrap;
	font-weight: normal;
}

#wave-controls em {
	font-style: normal;
	min-width: 88px;
	text-align: left;
}

#wave-form, #wave-timeline {
	width: calc(100% - 2rem)
}

#wave-form>wave {
	overflow: hidden !important;
}

#wave-controls {
	width: 100%;
}

.waveform-error {
	background: var(--guardsmanRed);
	color: #fff;
	padding: 1.1rem;
	border-radius: 3px;
}

.waveform-error span {
	font-weight: bold;
	padding-bottom: 5px;
	display: inline-flex;
}

.wavesurfer-region {
	min-width: 10px;
	max-width: 10px;
	min-height: 10px;
	max-height: 10px;
	border-radius: 10px;
	margin-left: -5px !important;
	top: 90% !important;
	background-color: var(--lochmara) !important;
}

.wavesurfer-region.interval {
	min-width: 0;
	min-width: initial;
	max-width: none;
	max-width: initial;
	min-height: 0;
	min-height: initial;
	max-height: 70%;
	border-radius: 0;
	margin-left: 0px !important;
	top: 15% !important;
	background-color: rgba(0, 121, 208, 0.2) !important;
}

.wavesurfer-region.invalid {
	background-color: var(--guardsmanRed) !important;
}

.wavesurfer-region.generated {
	background-color: transparent !important;
	top: auto !important;
	bottom: 2px;
	z-index: 0 !important;
	min-width: 0;
	min-width: initial;
	max-width: none;
	max-width: initial;
	min-height: 0;
	max-height: 0;
	border-radius: 0;
	border-bottom: 7px dotted var(--mercury);
	margin-left: 5px !important;
	border-style: dotted;
	border-color: var(--mercury);
	border-image-source: url(/static/media/dots-gray.5271c1ec.svg) !important;
	border-image-slice: 30% 26% !important;
	border-image-repeat: round !important;
	border-width: 8px 0 0 0;
}

.replaceMetadata {
	font-size: smaller;
	justify-content: flex-end;
	justify-items: flex-end;
	align-items: center;
	display: flex;
	flex-grow: 1;
}

.dropdown {
	min-width: 120px;
	font-size: 0.8rem;
	text-transform: none;
	text-transform: initial;
}

.dropdown .dropdown__control {
	min-height: 32px;
	height: 32px;
	border-radius: 3px;
	letter-spacing: normal;
}

.dropdown .dropdown__control--is-focused {
	border-color: var(--lochmara) !important;
	box-shadow: none !important;
}

.dropdown .dropdown__control--is-disabled {
	background: transparent;
	opacity: 0.5;
}

.dropdown .dropdown__placeholder {
	color: var(--lochmara) !important;
}

.dropdown .dropdown__indicator-separator {
	display: none !important;
}

.dropdown .dropdown__dropdown-indicator {
	padding: 2px;
	color: var(--lochmara) !important;
}

.dropdown .dropdown__menu {
	background: #fff;
	font-size: 0.8rem;
	color: #444;
	letter-spacing: normal;
	margin-top: 0;
	left: 0;
	font-weight: normal;
}

.dropdown .dropdown__option:hover {
	background: var(--lochmara);
	color: #fff;
}

.appMain .dropdown {
	min-width: auto;
}

.appMain .dropdown .dropdown__control {
	width: 155px;
	font-weight: normal;
	border-color: var(--lochmara);
}

.appMain .events .dropdown .dropdown__control {
	width: 110px;
}

.buttonGroup>span {
	display: flex;
	border: 1px solid var(--silver);
	width: 315px;
}

.buttonGroup>span>label {
	min-width: 40px;
	padding: 0.2rem;
	display: flex;
	align-items: center;
}

.buttonGroup>span>label>input[type='radio'] {
	padding: 0 0.2rem 0 0;
}

.numberOfAds {
	flex-direction: column;
}

.numberOfAds .explanation {
	padding: 0.2rem 0.5rem;
	font-style: italic;
	color: var(--silver);
}

.legal {
	align-items: flex-end;
	min-height: auto !important;
	height: 100%;
}

.legal>* {
	padding: 0 1rem;
	white-space: nowrap;
	text-decoration: none;
	color: var(--tundora);
}

.captchaWrapper {
	border: 2px dashed var(--lochmara) !important;
	background: #fff;
	border-radius: 5px;
	width: 100% !important;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.rssFeed h2 {
	margin-bottom: 2rem;
}

.rssFeed ul {
	width: 100%;
	padding: 1rem;
	border-radius: 3px;
	background-color: #fff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.09);
}

.rssFeed ul>li>h3 {
	color: var(--lochmara);
	margin-bottom: 0.5rem;
	display: flex;
	align-items: center;
}

.rssFeed ul>li>h3>section {
	display: inline;
}

.rssFeed ul>li>h3>section>span {
	font-size: smaller;
	display: inline-block;
	background: var(--selectiveYellow);
	border-radius: 2px;
	margin: 2px 0 2px 4px;
	padding: 3px 0.6rem;
	color: #444;
	font-weight: normal;
}

.rssFeed ul>li>h3>section>span.ok {
	background: var(--limeade);
	color: #fff;
}

.rssFeed ul>li {
	margin-top: 1rem;
	margin-bottom: 2rem;
}

.rssFeed ul>li>p {
	max-width: 800px;
}

.rssFeed ul>li>span {
	font-size: smaller;
	font-weight: bold;
	color: #000;
}

.rssFeed button {
	background: var(--lochmara);
	color: #fff;
	border-radius: 3px;
	padding: 2px 10px;
	margin-left: 1rem;
	display: inline-flex;
	height: 1.5rem;
	letter-spacing: 1.08px;
	font-size: 0.75rem;
	text-transform: uppercase;
	display: flex;
	align-items: center;
}

.rssFeed button .loading .spinner-wave>div {
	background-color: #fff;
}

.rssFeed ul>li>pre {
	background: var(--mercury);
	padding: 0.5rem;
	max-width: 800px;
}
html, body {
    padding: 0;
}
