.buttonBase {
    position: relative;
    height: var(--button-height-short);
    padding: 0 8px; /* safety padding */
    border-radius: 5px;
    font-size: var(--font-size-level-four);
    font-weight: var(--font-weight-medium);
    text-decoration: none;
    letter-spacing: var(--standard-letter-spacing);
    width: 100%;
}

.buttonNeutral {
    border: 1px solid var(--lochmara);
    background-color: var(--white);
    color: var(--lochmara);
}

.buttonPrimary {
    background: var(--lochmara);
    color: var(--white);
}

.buttonAttention {
    background-color: var(--guardsmanRed);
    color: var(--white);
}

.buttonTall {
    height: var(--button-height-tall);
}

.buttonInfoBadge {
    background: var(--mineShaft);
    color: var(--white);
}

.buttonInfoBadge > svg {
    margin-right: 5px;
    height: 15px;
    vertical-align: sub;
    line-height: 1.4;
}

/* unfortuante selector to override a Seamus concern */
.buttonInfoBadge > svg + span {
    font-family: var(--font-stack-system);
    font-size: var(--font-size-level-four);
    color: var(--white);
    line-height: 1.4;
}

/* button groups */
.buttonContainer {
    clear: left;
    border-top: 1px solid #ddd;
}

.buttonContainer button {
    line-height: .9;
}

.buttonContainer ul {
    display: inline-block;
    overflow: hidden;
    margin-bottom: 0;
    padding: var(--card-padding);
}

.buttonContainer li {
    float: left;
    width: inherit;
    margin-bottom: 0;
    list-style: none;
}

.buttonContainer li {
    padding-right: 5px;
    padding-left: 5px;
}

.buttonContainer li:first-child {
    padding-left: 0;
}

.buttonContainer li:last-child {
    padding-right: 0;
    margin-bottom: 0;
}
/* card styles - e.g. Seamus resources, Station Syndication story item */
.cardBase {
    margin: 8px 0;
    padding: 15px;
    border: 1px solid #ddd;
    background-color: var(--alabaster);
    letter-spacing: var(--standard-letter-spacing);
    cursor: -webkit-grab;
    cursor: grab;
}

.cardWrap {
    position: relative;
    overflow: hidden;
    padding: var(--card-padding);
}

.cardMetadata {
    overflow: hidden;
    margin-bottom: 15px;
}

.cardMetadata:last-child {
    margin-bottom: 0;
}

.cardMetadataHeading {
    margin-bottom: 0;
    color: var(--tundora);
    font-size: var(--font-size-level-four);
    font-weight: var(--font-weight-medium);
}

.cardMedia {
    float: right;
    width: calc(50% + 20px);
    padding-left: 20px;
}

.cardMediaSubdued {
    float: right;
    width: calc(25% + 20px);
    padding-left: 20px;
}

/* label/heading and character count */
.headingAndCountGroup {
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.headingAndCountGroup > h3 {
    margin: 0;
    font-size: var(--font-size-level-four);
    font-weight: var(--font-weight-medium);
}

.headingAndCountGroup > h3 > label,
.headingAndCountGroup > h3[role="button"] {
    cursor: pointer;
}

.headingAndCountGroup > div {
    margin-left: auto;
    font-size: var(--font-size-level-four);
    line-height: 1.06em;
}

.headingAndCountGroup > div > b {
    margin-right: 2px;
    font-weight: var(--font-weight-medium);
}

.overlay {
    position: fixed;
    overflow-y: auto; /* if modal gets too tall, user can scroll the overlay vertically */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 4; /* above story-lock overlay (2) and resources (3) */
    width: 100%;
    height: 100%;
    background: rgba(32, 32, 32, 0.5);
    font-family: var(--font-stack-system);
    line-height: 1.2;
}

.modal {
    overflow: visible;
    position: relative;
    top: 45px;
    left: 50%;
    transform: translateX(-50%);
    width: 750px;
    margin: auto 0 25px;
    padding: 25px;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    background-color: var(--white);
    color: #000;
    letter-spacing: var(--standard-letter-spacing);
}

.modalClose {
    font-weight: var(--font-weight-medium);
}

.modalHeader {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.modalTitle {
    float: left;
    margin: 0;
    color: var(--mineShaft);
    font-size: var(--font-size-level-one);
    font-weight: var(--font-weight-regular);
}

.modalSectionHeader {
    margin: 20px 0 10px;
    color: var(--mineShaft);
    font-weight: var(--font-weight-medium);
}

.alertTitle {
    float: left;
    margin: 0;
    color: var(--mineShaft);
    font-size: var(--font-size-level-two);
    font-weight: var(--font-weight-medium);
}

.modalButtonGroup {
    clear: left;
    overflow: hidden;
    margin-bottom: 0;
}

.modalButtonGroup li {
    float: left;
    padding-right: 10px;
}

.modalButtonGroup button,
.modalButtonGroup a {
    height: var(--button-height-short);
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.modalButtonGroup a {
    display: block;
    line-height: var(--button-height-short);
}

/* typeahead item in modal */
.modal [aria-expanded="true"] + div > div {
    display: flex;
    align-items: center;
    height: 52px;
    padding-left: 16px;
    border-top: 1px solid var(--silverChalice);
    border-right: 1px solid var(--silverChalice);
    border-left: 1px solid var(--silverChalice);
    font-size: var(--font-size-level-three);
    cursor: pointer;
}

.modal [aria-expanded="true"] + div > div:last-child {
    border-bottom: 1px solid var(--silverChalice);
}
/* high level modules, such as "Associations" in Seamus */
.moduleBase {
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #e9e9e9;
    background-color: var(--white);
    letter-spacing: var(--standard-letter-spacing);
}

.moduleBase::after {
    content: "";
    display: table;
    clear: both;
}

.moduleHeader {
    border-bottom: 1px solid #d6d6d6;
    margin-bottom: 10px;
}

.moduleHeading {
    color: var(--tundora);
    font-size: var(--font-size-level-two);
    font-weight: var(--font-weight-regular);
    line-height: 1.06;
}

/* utilities */
.visuallyHidden {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.clearfix::after {
    content: "";
    display: table;
    clear: both;
}
